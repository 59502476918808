.manager-balance {
    font-weight: 500;
    font-size: 70px;
}

.manager-margin-40 {
    width: 40px;
}

.manager-margin-33 {
    width: 33px;
}

.manager-margin-30-top {
    margin-top: 30px;
}

.manager-margin-25-top {
    margin-top: 25px;
}

.manager-margin-40-top {
    margin-top: 40px;
}

.manager-likes {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #4A4A4A;
    position: absolute;
    top: 40px;
    width: 150px;
    right: 0;
    margin-right: -20px;
}

.manager-likes > div {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 4px 0;
}
.manager-likes img {
    margin-right: 5px;
}

.manager-tips {
    margin-top: 25px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #575757;
}

.manager-pie {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -10px;
    margin-right: -20px;
}

.manager-tips span {
    display: block;
    margin-top: 5px;
    font-weight: 600;
    font-size: 40px;
    line-height: 49px;
    color: #3D3D3D;
}

.manager-tips .small span {
    font-size: 20px;
}

.manager-tips .small {
    text-decoration: underline;
}

.manager-other {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #575757;
}

.manager-other span {
    margin-top: 10px;
    display: block;
    font-weight: 600;
    font-size: 60px;
    line-height: 73px;
    letter-spacing: -0.02em;
    color: #3D3D3D;
}

.manager-icon {
    width: 54px;
    height: 54px;
    border-radius: 54px;
    background: #fff;
    position: absolute;
    align-items: center;
    display: flex;
    justify-content: center;
    right: -10px;
    top: -10px;
}

@media (max-width: 767.98px) {
    .manager-margin-40 {
        display: none;
    }
}

