.toggle-menu {
    margin-bottom: 33px;
    display: flex;
    flex-direction: column;
}

.toggle-menu-title {
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.toggle-menu-content {
    border: 1px #CCCCCC solid;
    border-radius: 37px;
    padding: 20px 20px 0px 20px;
    margin-top: 20px;
}

.toggle-menu-title svg {
    margin-right: 10px;
}