.list {
    height: 57px;
    padding-right: 27px;
    padding-left: 34px;
    margin-bottom: 20px;
    background: #F5F5F5;
    border-radius: 88px;
}

.list-text {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #575757;
    margin-left: 10px;
}

.list-sum {
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
}

.list-sum.green {
    color: #3EA300;
}

.list-sum.red {
    color: #C83102;
}

.table-search {
    margin: 30px 60px 50px 60px;
    align-items: center;
    display: flex;
}

.table {
    display:  table;
    margin-bottom: 50px;
}

.thead, .tbody {
    display:  table-row;
    height: 56px;
}

.thead {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    justify-content: center;
}

.thead .th, .tbody .td {
    display:  table-cell;
    height: 56px;
    padding-left: 11px;
    vertical-align: middle;
    padding-right: 11px;
}

.tbody.tchild {
    height: auto;
    min-height: auto !important;
}

.tbody.tchild .td {
    font-size: 13px;
    line-height: 14px;
    height: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    min-height: auto !important;
}

.tbody .td {
    border-left: 1px solid #cecece;
}

.tbody:not(.tchild) .td {
    border-top: 1px solid #cecece;
}

.tbody.last .td {
    font-weight: bold;
}

.thead .th:first-child, .tbody .td:first-child {
    padding-left: 59px;
    border-left: 0;
}

.table > .tbody:nth-child(2n ) {
    background: #fff;
}

.tbody:hover {
    background: #fff
}

.gray-table {
    padding-bottom: 36px;
}

.table-not-found {
    font-weight: 400;
    font-size: 17px;
    color: #575757;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 25px;
}

.th.break, .td.break {
    display: none;
}

.rdt {
    position: relative;
    flex: 1;
    display: flex;
}

.rdt input {
    text-align: center;
    background-color: #fff;
}

.table-checkbox {
    height: 60px;
    background: #FFFFFF;
    border-radius: 93px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #FF4ADD;
    padding: 0 20px;
}

.table-checkbox input {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    accent-color: #FF4ADD;
}
.table-checkbox input::marker  { color: #fff; }

.table-search > * {
    margin: 0 5px 10px 5px;
}

.td .mobile {
    display: none;
}


.word-no-wrap {
    white-space: nowrap;
}

.bottom30 {
    margin-bottom: 30px !important;
}

@media (max-width: 767.98px) {
    .thead .th, .tbody .td {
        padding-left: 5px;
        padding-right: 5px;
    }

    .table-search {
        margin: 30px;
        flex-direction: column;
        display: block;
    }

    .tbody .td:not(:first-child) {
        color: rgba(114, 114, 114, 1);
    }

    .rdt input {
        flex: 1;
    }

    .th.break, .td.break {
        display: flex;
    }

    .table {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }

    .gray-table {
        padding-bottom: 35px;
    }

    .thead {
        display: flex;
        flex-direction: row;
    }

    .tbody {
        display: flex;
        flex-direction: row;
        min-height: 56px;
        height: auto;
        padding: 10px 5px;
        flex-wrap: wrap;
        flex: 1 1 100%;
    }

    .thead {
        display: none;
    }

    .tbody .td {
        height: auto;
        border: 0;
        border-bottom-width: 1px;
        display: flex;
        align-items: center;
        white-space: nowrap
    }
    .tbody .td:first-child {
        padding-left: 15px;
        font-weight: 500;
    }

    .thead .th:first-child {
        padding-left: 15px;
    }

    .td.col-2 {
        align-self: stretch;
    }

    .td.end {
        flex: 1 1;
        justify-content: flex-end;
        display: flex;
    }

    .td.break {
        flex-basis: 100%;
        width: 0;
        margin-top: 25px;
    }

    .tbody .td.xs-visible-bold {
        font-weight: 500;
        color: black;
    }

    .word-wrap {
        white-space: normal !important;
    }
    .td .mobile {
        display: block;
        width: 50%;
    }
    .td.row {
        width: 100%;
    }
    .tbody:not(.tchild) .td {
        border-top: 0px solid #cecece;
    }
}

