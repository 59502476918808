.tips-cards {
    font-weight: 400;
    font-size: 17px;
    color: #575757;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
    margin-top: 10px;
}

.tips-cards img {
    margin-left: 17px;
}

.tips-cards-wrapper {
    display: flex;
    align-items: center;
}

.tips-list {
    margin-top: 49px;
}

.tips-span-container {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}

.tips-span {
    width: 19%;
    background-color: #ffffff;
    border-radius: 60px;
    height: 60px;
    padding: 0 20px;
    text-align: center;
    vertical-align: middle;
    color: #FF4ADD;
    line-height: 60px;
}

.save label {
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom:20px;
}

.save label input {
    width: 30px;
    height: 30px;
    margin-top: 0;
    margin-right: 15px;
}

.tips-card-out input:not(:disabled) {
    background: #fff;
}

.saved-card {
    box-shadow: 4px 4px 15px rgba(255, 255, 255, 0.2);
    border-radius: 55px;
    height: 55px;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    flex-direction: row;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    background: white;
    margin-bottom: 10px;
    cursor: pointer;
}

.saved-card.active {
    background: #ff4add;
    color: white;
}

.tips-saved-info {
    color: #575757;
    margin-bottom: 10px;
}

@media (max-width: 767.98px) {
    .tips-row {
        flex-direction: column-reverse;
    }
}
