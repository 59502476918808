.images input[type=file] {
  display: none;
}

.images .branding {
  background-color: #FFFFFF;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
}

.images .logo {
  width: 83px;
  height: 40px;
  border-radius: 20px;
  border-color: rgb(225, 225, 225);
  border-style: solid;
  border-width: 1px;
  padding: 8px 22px 12px 22px;
  background-color: #FFFFFF;
}

.images .logo-image {
  width: 100%;
  height: 100%;
}

.images .back {
  width: 600px;
  height: 106px;
  border-color: #FFFFFF;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.logo-container {
  margin-top: 16px;
}
