/*!
 * Bootstrap  v5.3.0-alpha1 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

 .calendar-image {
  width: 30px;
 }
 .calendar-text {
  margin: auto 0px auto 13px !important;
  font-size: 22px;
 }

 .wcharts-container {
  justify-content: flex-start;
 }

 .tips-chart-container {
  flex-grow: 1;
 }
 
 .tips-chart {
  margin-bottom: 40px;
 }

 .likes-doughnut-container {
  flex-grow: 1;
  margin-left: 38px;
  margin-right: 16px;
 }
 
 .likes-doughnut {
  margin-bottom: 40px;
  height: 254px;
 }

 .wpicker {
 }

 .wpicker.first {
  margin-left: 59px;
 }

 .wpicker.second {
  margin-left: 11px;
 }

 .wsearch {
  margin-left: 73px;
  margin-right: 90px;
 }

 .wpicker > input {
  border-radius: 24px;
  width: 100%;
  color: #000000;
  padding: 0 20px;
}
.wsearch > input {
  border-radius: 24px;
  width: 100%;
  padding: 0 20px;
}

.wsearch > span {
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.wcentered {
  justify-content: center !important;
  display: flex;
  margin-top: -173px;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
  }
  .input-group > .wform-control {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
  }

  .input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    white-space: nowrap;
    background-color: #FFFFFF;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: 0.375rem;
  }

  .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: calc(var(--bs-border-width) * -1);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .wform-control {
    display: block;
    width: 100%;
    background-color: #FFFFFF !important;
    padding: 0.375rem 0.75rem;
  }
  @media (prefers-reduced-motion: reduce) {
    .wform-control {
      transition: none;
    }
  }
  .wform-control::-moz-placeholder {
    color: var(--bs-secondary-color);
    opacity: 1;
  }
  .wform-control::placeholder {
    color: var(--bs-secondary-color);
    opacity: 1;
  }
  .wrow {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
  }
  .wrow > * {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
  }

  .wcol-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  
  .wcol-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  
  .wcol-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  
  .wcol-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .wcol-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  
  .wcol-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .wmb-1 {
    margin-bottom: 0.25rem !important;
  }
  
  .wmb-3 {
    margin-bottom: 1rem !important;
  }  

  @media (max-width: 767.98px) {
    .wcol-2 {
      width: 100%;
    }

    .wcol-5 {
      width: 100%;
    }

    .wcol-6 {
      width: 100%;
    }

    .wcol-7 {
      width: 100%;
    }

    .wcol-8 {
      width: 100%;
    }
  
    .row.mobile .wcol-2 {
      margin-left: -0.75rem !important;
    }
    .row.mobile .wcol-7 {
      margin-left: -0.75rem !important;
    }
    .likes-doughnut-container {
      flex-grow: 1;
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 25px;
     }

     .wpicker.first {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 20px;
     }
    
     .wpicker.second {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 20px;
     }
    
     .wsearch {
      margin-left: 20px;
      margin-right: 20px;
     }
    
  }

  
  