.order-title {
    font-weight: 500;
    font-size: 30px;
    line-height: 37px;
    margin-bottom: 14px;
}

.order-button {
    margin-top: 10px;
}

.order-waiter {
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    color: #FF4ADD;
    margin-top: 20px;
}

.order-waiter-title {
    margin-top: 50px;
}

.gray-order {
    padding-top: 10px;
    padding-bottom: 0;
}

.gray-order .table {
    margin-bottom: 0;
}

.gray-order .table .tbody:last-child .td {
    padding-bottom: 20px;
}

.order-table, .order-total {
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
}

.order-table{
    margin-top: 65px;
}

.order-title-tables {
    margin-top: 50px;
    margin-bottom: 30px;
}

.order-paid {
    margin-top: 15px;
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    color: #FF4ADD;
}

.order-paid span {
    margin-left: 5px;
    font-weight: 600;
}

.order-guests {
    margin-top: 10px;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    color: #FF4ADD;
}
.order-guests span {
    font-weight: 700;
}

.order-guests-block {
    margin-top: 30px;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
}

.order-waiter-item {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    width: 100%;
    padding: 5px;
}

.order-waiter-item-name {
    width: 70%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    font-size: 20px;
}

.order-waiter-item-image {
    height: 20px;
    transform: rotate(180deg);
}

@media (max-width: 767.98px) {
    .gray-order {
        padding-top:35px;
    }

    .gray-order .table .tbody:last-child .td {
        padding-bottom: 0px;
    }

    .gray-order .table .tbody:last-child {
        padding-bottom: 40px;
    }

    .tbody .td.sx-height-51 {
        height: 30px;
    }
}
