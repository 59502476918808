

.login {
    width: 100%;
    background-color: #FF4ADD;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-logo {
    margin-bottom: 56px;
    display: block;
    width: 237px;
    align-self: center;
}

.form-control.login-input {
    color: #737373;
    outline: none;
    background: #FFFFFF;
    border-radius: 62px;
    height: 62px;
    padding-left: 39px;
    border-color: #fff;
}

.form-control.login-input::placeholder {
    color: #AAAAAA;
}

.form-control.login-input:-webkit-autofill, .form-control.login-input:-internal-autofill-selected {
    color: #AAAAAA !important;
    background: #FFFFFF !important;
}

.login-input-wrapper {
    margin-bottom: 14px;
}

.login-button.btn.btn-primary {
    margin-top: 58px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 62px;
    width: 100%;

    background: #4A4A4A;
    box-shadow: 4px 4px 15px rgba(74, 74, 74, 0.32);
    border-radius: 62px;

    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    border-color: #4A4A4A;
}

.login-link {
    margin-top: 20px;

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    display: block;
    align-self: center;
    text-align: center;
    letter-spacing: -0.02em;

    color: #FFFFFF;
}
.login-link:hover {
    color: #FFFFFF;
    text-decoration: none;
}

.login-flex {
    display: flex;
    flex-direction: column;
}