.category {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.add-category {
    margin-top: 10px;
}

.category-item {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.category-name {
    width: 70%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    font-size: 20px;
}

.category-name.disabled {
    color: #999999;
}

.products-list {
    border-radius: 20px;
    border: 1px solid lightgray;
    gap: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.product-item {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    width: 100%;
    padding: 5px;
}

.product-name {
    width: 70%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    font-size: 20px;
}

.product-name.disabled {
    color: #999999;
}

.modal-products-container {
    display: flex;
    flex-direction: column;
}

.modal-products-list {
    overflow: auto;
    flex-grow: 1;
}

.list-product-item {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.list-product-name {
    flex-grow: 1;
}
.list-product-button {
    flex-grow: 0;
}

.list-product-category {
    font-size: 16px;
    color: gray;
}

input[type=file] {
    display: none;
}

.product-image-container {
    width: 390px;
    height: 192px;
    margin-top: 20px;
}

.product-image {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.edit-icon {
    height: 20px;
}

.arrow-up-icon {
    height: 20px;
    transform: rotate(90deg);
}

.arrow-down-icon {
    height: 20px;
    transform: rotate(-90deg);
}

.arrow-right-icon {
    height: 20px;
    transform: rotate(180deg);
}

.toggle-menu .toggle-menu-content {
    padding: 10px 20px 10px 20px;
}

.menu-remove-icon {
    padding: 5px;
    height: 45px;
}

.menu-remove-icon:hover {
    cursor: pointer;
}
