.menu {
    background: #F7F7F7;
    border-radius: 65px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    flex: 1;
}

.menu > a:not(.menu-select) {
    background: #FFFFFF;
    border-radius: 75px;
    height: 57px;
    margin-top: 20px;
    flex-direction: row;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 27px;
}

.menu > a.active, .menu > a.active > div {
    background: #FF4ADD;
    border-radius: 75px;
    color: white;
}

.menu > a > div {
    width: 84px;
    height: 57px;
    background: #4A4A4A;
    border-radius: 75px;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-right: 25px;
}

.menu-select {
    margin-top: 30px;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.menu > .menu-select.active {
    background: transparent;
    color: #FF4ADD;
}

.menu-select-arrow {
    margin-left: 10px;
}

.menu-toggle {
    margin-right: 20px;
    margin-left: 10px;
    cursor: pointer;
    display: none;
    height: 80px;
    background: rgba(255, 74, 221, 0.08);
    border-radius: 74px;
    width: 80px;
    justify-content: center;
    align-items: center;
}

.menu-toggle div {
    width: 40px;
    height: 2px;
    margin: 5px 0;
    background: #000;
    border-radius: 20px;
}

@media (max-width: 767.98px) {
    .menu {
        display: none;
    }

    .menu.open {
        display: flex;
        margin: 0 20px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        border-radius: 0;
        overflow: scroll;
    }
    .menu-toggle {
        display: flex;
        flex-direction: column;
    }

    .content {
        margin-left: 0;
        padding: 20px;
    }
}