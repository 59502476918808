.reports-title {
    margin-left: 59px;
}

.nowrap {
    white-space: nowrap;
}

.td.collapse-button, .collapse-button {
    align-items: center;
    gap: 8px;
    margin-left: 5px;
    flex-direction: row;
    cursor: pointer;
    display: flex;
}

.child {
    margin-left: 20px;
}

@media (max-width: 767.98px) {
    .reports-title {
        margin-left: 20px;
    }
}