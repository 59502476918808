.header {
    display: flex;
    margin-top: 34px;
    margin-bottom: 40px;
}

.header-logo {
    width: 58px;
    height: 54px;
    margin-right: 15px;
}

.header-username {
    display: flex;
    height: 80px;
    background: rgba(255, 74, 221, 0.08);
    border-radius: 74px;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    text-align: center;
    letter-spacing: -0.02em;
    padding-left: 54px;
    color: #000000;
    padding-right: 23px;
    align-items: center;
}

.header-avatar {
    margin-left: 14px;
    width: 61px;
    height: 61px;
}

@media (max-width: 767.98px) {
    .header {
        margin: 25px 10px 15px 10px;
    }

    .header-logo {
        margin-left: 10px;
        margin-bottom: 25px;
    }

    .header-logo-text {
        margin-bottom: 25px;
    }

    .header-username {
        flex: 1;
        width: 10px;
        justify-content: flex-end;
    }

    .header-username > div {
        text-overflow: ellipsis;
        overflow: hidden;
    }
}