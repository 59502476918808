html, body {
    height: 100%;
}

#root {
    min-height: 100%;
}

* {
    font-family: "Montserrat", sans-serif !important;
    letter-spacing: -0.02em;
}
a {
    text-decoration: none;
}

body {
    overflow-y: scroll;
}

#root {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.wrapper {
    max-width: 1380px;
    padding: 68px 0 0;
    align-self: start;
    flex: 1;
}

.flex {
    display: flex;
}

.row {
    display: flex;
    flex-direction: row;
}

.col {
    display: flex;
    flex-direction: column;
}

.col-2 {
    flex: 1;
}

.grow-1 {
    flex-grow: 1;
}

.between {
    justify-content: space-between;
}

.content {
    margin-left: 40px;
    flex: 2.5 1;
}

.title {
    font-weight: 500;
    font-size: 30px;
    line-height: 37px;
    color: #A9A9A9;
}

.title-margin {
    margin-top: 50px;
    margin-bottom: 30px;
}

.block {
    background: #F5F5F5;
    border-radius: 35px;
    padding: 25px 38px;
}

.btn.btn-primary {
    border: 0;
    outline: none;
    background: #FF4ADD;
    padding: 0 34px;
    gap: 8px;
    box-shadow: 4px 4px 15px rgba(255, 74, 221, 0.2);
    border-radius: 55px;
    color: white;
    cursor: pointer;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    flex: 1;
}

.btn.green {
    background: #3EA300;
}

.btn.btn-primary.long {
    flex: 1;
}

.btn:disabled {
    opacity: 0.4;
    cursor: default;
    box-shadow: none;
}

.btn.btn-primary:hover {
    box-shadow: none;
}

.btn.btn-outline-secondary {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: 1px solid #000;
    border-radius: 62px;
    color: #000;
    outline: none;
    height: 55px;
    min-height: 55px;
    padding: 0 34px;
    font-size: 20px;
    cursor: pointer;
}

.relative {
    position: relative;
}

.middle {
    display: flex;
    align-items: center;
}

.clear {
    clear: both;
}

a {
    color: black;
}
ul {
    margin: 0;
    padding: 0;
}
li {
    list-style: none;
}

.pagination {
    margin: 0;
    margin-top: 10px;
    padding: 0;
    flex-direction: row;
    display: flex;
    justify-content: center;
}

.pagination li {
    display: flex;
}

.pagination li a {
    width: 38px;
    height: 39px;
    background: #D9D9D9;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    flex: 1;
    align-items: center;
    letter-spacing: -0.02em;
    color: #575757;
    margin: 0 4px;
    border-radius: 40px;
    text-decoration: none;
    justify-content: center;
}

.pagination li a.active {
    color: #FFFFFF;
    background: #4A4A4A;
}

input, select, textarea {
    height: 60px;
    background: #FFFFFF;
    border-radius: 60px;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: -0.02em;
    /* color: #FF4ADD; */
    border: 0;
    outline: none;
    padding: 0 40px;
    line-height: 24px;
}

input[type=text] {
    line-height: 54px;
}

input::-webkit-file-upload-button {
    border: 0;
    background: transparent;
    height: 60px;
    line-height: 60px;
}

select {
    border-right: 15px solid transparent;
}

textarea {
    border-radius: 60px;
    padding: 20px 40px;
}

input::placeholder, textarea::placeholder {
    color: #AAAAAA;
}

input:disabled, textarea:disabled {
    background: #CCCCCC;
    color: #000000;
}

div.input {
    position: relative;
    margin-bottom: 10px;
    display: flex;
}

div.input img {
    position: absolute;
    top: 15px;
    left: 15px;
}
div.input.rub img {
    left: 18px;
}

div.input input {
    padding-left: 60px;
    height: 49px;
}

input.long {
    display: flex;
    flex: 1;
}


.gray-wrapper, .form-wrapper {
    background: #F7F7F7;
    border-radius: 65px;
    display: flex;
    flex-direction: column;
}

.form {
    padding: 43px 34px 0px 34px;
    margin-bottom: 20px;
}

.alert {
    padding: 20px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #575757;
}

.alert.alert-info {
    color: #084298;
    background-color: #cfe2ff;
    border-color: #b6d4fe;
    border-radius: 20px;
}

label {
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    flex-direction: column;
    margin-bottom: 33px;
    display: flex;
}

label input, label select {
    display: flex;
    margin-top: 15px;
}

.gray {
    color: #b4b4b4;
    padding-left: 10px;
}

.xs-visible, .tbody.xs-visible {
    display: none;
}

.instruction-station-wrapper {
    display: flex;
    flex-direction: row;
}

.instruction-station {
    justify-content: space-between;
    align-items: center;
    height: 200px;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;
    margin: 5px;
    background: #F7F7F7;
    border-radius: 65px;
    border: 1px transparent solid;
    transition: 0.2s;
    font-size: 20px;
}

.instruction-station:hover {
    border: 1px #a2a2a2 solid;
}

.instruction-station img {
    max-width: 80%;
    max-height: 180px;
}
.instruction-station.r-keeper {
    padding-top: 50px;
    height: 150px;
}

.advanced {
    text-decoration: underline;
    display: block;
    padding: 7px 0 7px 0;
    margin-bottom: 5px;
}

.top-align {
    vertical-align: top !important;
}

.reports-display-none {
    display: none;
}

.reports-display-block {
    display: block;
    margin-top: 20px;
}

.reports-display-block A {
    margin-left: 40px;
}

.reports-product-name {
    display: inline-block;
    flex-grow: 1;
}

.reports-small-show-button {
    display: inline-block !important;
    height: 26px !important;
    margin-right: 10px;
}

.desktop-modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.33);
    z-index: 12;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    visibility: visible;
}

.tipswish-modal {
    visibility: hidden;
}

.tipswish-modal-content {
    display: flex;
    flex-direction: column;
    padding-left: 32px;
    padding-right: 32px;
    position: absolute;
    width: 404px;
    /* height: 605px; */
    max-height: 100vh;
    left: calc(50% - 404px/2);
    /* top: calc(50% - 605px/2 - 0.5px); */
    background: #FFFFFF;
    border-radius: 20px;
    overflow: auto;
}

.tipswish-modal-content textarea {
    min-height: 80px;
}

.tipswish-modal-content button {
    min-height: 56px;
}

.tipswish-header-back {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-top: 7px;
    gap: 20px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 32px;
}

.tipswish-header-back-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    letter-spacing: -0.02em;
    color: #181818;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.tipswish-title {
    margin-top: 27px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 37px;
    letter-spacing: -0.02em;
    color: #A9A9A9;
    padding-bottom: 8px;
}

.tipswish-label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #181818;
    margin-top: 32px;
}

.tipswish-name-input {
    box-sizing: border-box;
    gap: 10px;
    height: 56px;
    border: 0.5px solid #ADADAD;
    border-radius: 20px;
    margin-top: 12px;
}

.tipswish-wish-input {
    box-sizing: border-box;
    height: 155px;
    border: 0.5px solid #ADADAD;
    border-radius: 20px;
    margin-top: 12px;
    resize: none;
}

.tipswish-save {
    margin-top: 40px;
    height: 55px;
    min-height: 55px;
    padding: 0px;
    flex: unset !important;
}

.prepay-modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.33);
    z-index: 12;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    visibility: visible;
}

.prepay-modal-content {
    display: flex;
    flex-direction: column;
    padding-left: 32px;
    padding-right: 32px;
    position: absolute;
    width: 404px;
    /* height: 605px; */
    max-height: 100vh;
    left: calc(50% - 404px/2);
    /* top: calc(50% - 605px/2 - 0.5px); */
    background: #FFFFFF;
    border-radius: 20px;
    overflow: auto;
}

.prepay-modal-title {
    margin-top: 24px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #000000;
    padding-bottom: 8px;
}

.prepay-subtitle {
    margin-top: 8px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #000000;
    padding-bottom: 8px;
}

.prepay-title {
    margin-top: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #000000;
    padding-bottom: 8px;
}

.prepay-label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #181818;
    margin-top: 32px;
}

.prepay-input {
    box-sizing: border-box;
    height: 53px;
    line-height: 53px;
    border: 0.5px solid #ADADAD;
    border-radius: 20px;
    margin-top: 12px;
    font-size: 14px;
    padding-left: 20px;
}

label.prepay-input {
    margin-bottom: 0;
}

.prepay-input select {
    padding: 0;
    margin-top: 0;
    font-size: 14px;
}

.prepay-date {
    border: 0.5px solid #ADADAD;
    border-radius: 20px;
    margin-top: 12px;
}

.prepay-date input {
    font-size: 14px;
    padding: 0;
}

.prepay-list-search {
    width: auto;
}

.prepay-list-date {
    width: 158px;
}

.prepay-list-date input {
    padding: 0;
    width: 158px;
    color: #FF4ADD;
}

.prepay-text-input {
    box-sizing: border-box;
    height: 155px;
    min-height: 155px;
    font-size: 14px;
    border: 0.5px solid #ADADAD;
    border-radius: 20px;
    margin-top: 12px;
    resize: none;
}



.avatar {
    padding-top: 16px;
    width: 90px;
    height: 90px;
    cursor: pointer;    
    border-radius: 50%;
}

.avatar-container {
    /* display: flex; */
}

.avatar-container input[type=file] {
    display: none;
}

.avatar-icon {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
    position: relative;
}

.avatar-pencil {
    width: 32px;
    height: 32px;
    bottom: 32px;
    position: relative;
    left: 58px;
}

.mail-confirm-popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.33);
}

.mail-confirm-popup-body {
    align-items: center;
    padding: 32px;
    gap: 20px;

    position: absolute;
    width: 340px;
    height: 212px;
    left: calc(50% - 404px/2);
    top: calc(50% - 276px/2 - 0.5px);
    background: #FFFFFF;
    border-radius: 20px;
}

.mail-confirm-popup-body DIV, BUTTON {
    width: 100%;
}

.mail-confirm-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.02em;
    color: #000000;    
}

.mail-confirm-cancel {
    background-color: transparent !important;
    box-shadow: none !important;
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    text-align: center !important;
    letter-spacing: -0.02em !important;
    color: #000000 !important;
    height: 55px !important;
    margin-top: 40px !important;
}

.lic {
    font-size: x-small;
    font-weight: 600;
    padding-bottom: 25px;
    margin-left: auto;
    margin-right: auto;  
}

.lic A {
    text-decoration: underline;
}

.add-type-modal {
    background-color: #F7F7F7;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    padding: 20px;
}

.add-type-modal.full-height {
    height: auto;
}

.add-type-buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

@media (max-width: 767.98px) {
    .form {
        padding: 0;
    }

    .form-wrapper {
        background: white;
    }

    .form input, .form select, .form textarea {
        background: rgba(244, 244, 244, 1);
    }

    .wrapper {
        padding-top: 0;
        max-width: 100vw;
    }

    .row.mobile {
        flex-direction: column;
    }

    .xs-hidden {
        display: none !important;
    }

    .tbody.xs-visible {
        display: flex !important;
    }

    .xs-visible {
        display: inline !important;
    }

    .btn {
        flex: 1;
    }

    input, textarea {
        display: flex;
    }

    .desktop-modal {
        visibility: hidden;
    }

    .prepay-modal {
        position: static;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: auto;
        visibility: visible;
        background: #FFFFFF;
    }

    .prepay-modal-content {
        display: flex;
        flex-direction: column;
        padding-left: 32px;
        padding-right: 32px;
        position: relative;
        width: 100%;
        left: 0;
        background: #FFFFFF;
        border-radius: 20px;
        overflow: auto;
    }

    .tipswish-modal {
        visibility: visible;
        display: flex;
        flex-direction: column;
    }
 
    .mail-confirm-popup-body {
        align-items: center;
        padding: 32px;
        gap: 20px;
    
        position: absolute;
        width: 297px;
        height: 202px;
        left: calc(50% - 361px/2 + 0.5px);
        top: calc(50% - 266px/2);
        background: #FFFFFF;
        border-radius: 20px;
    }

    .mail-confirm-text {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: #000000;    
    }

    .mail-confirm-cancel {
        background-color: transparent !important;
        box-shadow: none !important;
        font-family: 'Montserrat' !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 18px !important;
        line-height: 22px !important;
        text-align: center !important;
        letter-spacing: -0.02em !important;
        color: #000000 !important;
        height: 55px !important;
        margin-top: 40px !important;
    }
    
}

